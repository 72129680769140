import axios from "axios";
import { toast } from "react-toastify";
import { setMiscLoading } from ".";
import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";

export const getAllRestaurants = () => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    axios
      .get(`${API_URL}/urban-square/tenant-details/all`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ALL_TENANT_DETAILS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMiscLoading(false));
      });
  };
};

export const setSelectedTenant = (restID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    axios
      .get(
        `${API_URL}/urban-square/tenant-details?type=restaurants&rest_id=${restID}`
      )
      .then(async (res) => {
        if (res.data?.length > 0) {
          await dispatch({
            type: actionTypes.SET_SELECTED_TENANT_DETAILS,
            payload: res.data[0],
          });
        }
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMiscLoading(false));
      });
  };
};

// enum: [
//   "restaurants",
//   "ordertypes",
//   "categories",
//   "parentcategories",
//   "items",
//   "variations",
//   "addongroups",
//   "attributes",
//   "discounts",
//   "taxes",
// ],

export const fetchTenantParentCategories = (restID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    axios
      .get(
        `${API_URL}/urban-square/tenant-details?type=parentcategories&rest_id=${restID}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TENANT_PARENT_CATEGORIES_DETAILS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchTenantCategories = (restID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    axios
      .get(
        `${API_URL}/urban-square/tenant-details?type=categories&rest_id=${restID}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TENANT_CATEGORIES_DETAILS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchTenantItems = (restID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    axios
      .get(
        `${API_URL}/urban-square/tenant-details?type=items&rest_id=${restID}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TENANT_ITEMS_DETAILS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchTenantAddonGroup = (restID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    axios
      .get(
        `${API_URL}/urban-square/tenant-details?type=addongroups&rest_id=${restID}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TENANT_ADDON_GROUP_DETAILS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchTenantVariations = (restID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    axios
      .get(
        `${API_URL}/urban-square/tenant-details?type=variations&rest_id=${restID}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TENANT_VARIATION_DETAILS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchRestaurantMenu = (restID) => {
  return async (dispatch, getState) => {
    const currentRestId = getState()?.restaurant?.selectedTenant?.rest_id;
    if (currentRestId !== restID) {
      await dispatch({
        type: actionTypes.RESET_TENANT_MENU,
      });
      await dispatch(setSelectedTenant(restID));
      await dispatch(fetchTenantParentCategories(restID));
      await dispatch(fetchTenantCategories(restID));
      await dispatch(fetchTenantItems(restID));
      await dispatch(fetchTenantAddonGroup(restID));
      await dispatch(fetchTenantVariations(restID));
    }
  };
};

export const resetRestaurantMenu = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_TENANT_MENU,
    });
  };
};
