import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { Skeleton, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllRestaurants } from "../../redux/actions";
import WTFLogo from "./svgs/WTFLogo";
import Menu from "./svgs/Menu";
import { push } from "connected-react-router";
import { useHistory, useParams } from "react-router";

function Header() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const allTenants = useSelector((state) => state.restaurant.allTenants);
  const loading = useSelector((state) => state.misc.loading);
  const [restName, setRestName] = React.useState("");
  const selectedTenant = useSelector(
    (state) => state.restaurant.selectedTenant
  );
  const { restID } = useParams();

  useEffect(() => {
    if (history.location.pathname === "/") {
      dispatch(getAllRestaurants());
    }
  }, [dispatch, history]);

  useEffect(() => {
    if (selectedTenant?.data?.details?.menusharingcode === restID) {
      setRestName(selectedTenant?.data?.details?.restaurantname);
    } else {
      setRestName("");
    }
  }, [restID, selectedTenant]);

  return (
    <div className={classes.root}>
      <div className={classes.headRoot}>
        <div>
          <div className={classes.leftFlex}>
            <WTFLogo />
            <div>
              {!restName && (allTenants.length === 0 || loading) ? (
                <Skeleton
                  animation="wave"
                  variant="text"
                  style={{ width: 200, marginLeft: 16 }}
                />
              ) : (
                <>
                  <div className={classes.heading}>WELCOME TO</div>
                  <div className={classes.title}>
                    {restName ? restName : "URBAN SQUARE"}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className={classes.rightFlex}>
            <IconButton onClick={() => dispatch(push("/profile"))}>
              <Menu />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
