import React from 'react';

function WTFLogo() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.28904 18.1897L4.1375 19.7245L3.29015 27.7711C3.24362 28.1109 3.29392 28.4459 3.2564 28.7817C3.01011 30.9862 3.15115 33.5262 6.08194 33.6168C7.51517 33.6611 8.52793 33.0702 8.96897 32.1555C9.53578 30.9801 9.12397 28.5775 9.06076 27.1071L8.30199 20.0374L6.15584 18.9127C6.35517 18.7262 7.74579 18.0607 8.0958 17.9552C8.0958 17.2553 7.85444 16.509 7.85444 15.7798C9.24075 15.749 10.5032 14.4283 11.0031 13.3002C11.4828 12.2175 11.5585 11.3032 11.7412 10.2615C11.9073 9.31389 11.8817 5.68434 11.8802 4.61094C11.8791 3.76516 11.6519 1.86304 11.2951 1.41954C10.9831 1.03173 10.5658 0.742542 10.1617 1.21261C9.71794 1.72891 9.49824 6.02122 9.35019 7.07104C9.15919 8.42549 9.15665 9.42813 8.25672 8.87126C7.10897 8.16105 7.14235 2.19436 6.71642 1.41198C6.2687 0.589546 5.24258 0.763366 5.04869 2.69612C4.92644 3.91478 4.61813 7.7071 4.32777 8.48321C3.25187 11.3594 2.75702 4.36634 2.75124 4.32649C2.56009 3.00889 2.80705 1.74541 2.20752 1.17188C1.74498 0.729394 1.29396 1.02097 1.1578 1.46242C1.00624 1.95382 1.02155 4.27948 0.946562 4.94675C0.709986 7.05104 0.73578 9.83148 1.01006 11.929C1.08106 12.4719 1.22045 12.9316 1.44265 13.3448C1.86508 14.1304 2.42848 14.852 3.25786 15.2277C3.621 15.3923 4.14247 15.6837 4.55553 15.7798C4.47703 16.1162 4.41834 16.5951 4.39461 16.9847L4.28904 18.1897Z"
        fill="#E53A35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0269 4.69407C21.5333 4.69407 21.7439 6.6756 22.5894 7.25388C22.9664 7.51167 23.4109 7.58599 24.0269 7.58599V13.7715C24.0269 15.7754 27.1649 16.1665 27.1649 13.5305V7.58599C28.157 7.58599 29.5787 7.55143 29.5787 6.0597C29.5787 4.72932 28.0148 4.69407 27.1649 4.69407C27.1649 2.44563 27.1912 3.03223 28.3242 2.71865C29.4632 2.40345 29.6222 0.245017 27.9592 0.0448607C26.5924 -0.119671 25.524 0.145264 24.6566 1.2259C23.8556 2.22385 24.0269 3.49959 24.0269 4.69407Z"
        fill="#E53A35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0956 17.9552C7.74559 18.0608 6.35497 18.7262 6.15564 18.9128L8.30178 20.0374C13.4447 20.0374 29.2328 20.6719 33.5573 19.2702C34.8072 18.8651 33.0504 18.5295 32.6836 18.4637C26.018 17.2673 16.6994 17.896 9.78552 17.8687C9.10314 17.866 8.66609 17.9375 8.0956 17.9552Z"
        fill="#F1DEDE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9002 4.61371C14.556 4.64362 14.0496 5.07387 14.0496 6.30068C14.0496 7.11725 15.0632 7.48704 15.9002 7.50565V13.6912C15.9002 14.3919 16.4096 15.3781 17.3484 15.3781C18.4291 15.3781 19.0381 14.8479 19.0381 13.6912V7.50565C20.4981 7.50565 21.4519 7.42134 21.4519 5.81869C21.4519 4.91939 20.406 4.61371 19.5209 4.61371H19.0381C19.0381 3.42817 19.3487 0.99881 17.5898 0.99881C15.5557 0.99881 15.9002 3.21172 15.9002 4.61371Z"
        fill="#E53A35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.13724 19.7245L4.28879 18.1898C3.98373 18.1898 -1.97694 18.6351 0.680719 19.3268C1.66036 19.5818 3.15408 19.7589 4.13724 19.7245Z"
        fill="#F1DEDE"
      />
    </svg>
  );
}

export default WTFLogo;
