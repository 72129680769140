import * as actionTypes from "../actionTypes";

let initialState = {
  loading: "",
  imageItemSection: {},
  addonSelection: [],
  storeCart: "",
  localCart: [],
};

export const cartReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CART_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.SET_SELECTED_IMAGE:
      return {
        ...state,
        imageItemSection: action.payload,
      };
    case actionTypes.SAVE_SELECTED_ADDON:
      return {
        ...state,
        addonSelection: action.payload,
      };
    case actionTypes.SET_LOCAL_CART:
      return {
        ...state,
        localCart: action.payload,
      };
    case actionTypes.SET_STORE_CART:
      return {
        ...state,
        storeCart: action.payload,
      };
    case actionTypes.CLEAR_CART:
      return {
        ...state,
        storeCart: "",
        localCart: [],
      };
    case actionTypes.RESET_TENANT_MENU:
      return {
        loading: "",
        imageItemSection: {},
        addonSelection: [],
        storeCart: "",
        localCart: [],
      };
    default:
      return state;
  }
};
