import * as actionTypes from "../actionTypes";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import _ from "lodash";
import { saveOrder } from "./orderActions";

export const setCartLoading = (item) => ({
  type: actionTypes.SET_CART_LOADING,
  payload: item,
});

export const setImageSelection = (item) => ({
  type: actionTypes.SET_SELECTED_IMAGE,
  payload: item,
});

export const setSelectedAddon = (item) => ({
  type: actionTypes.SAVE_SELECTED_ADDON,
  payload: item ? [item] : [],
});

export const removeAddonSelection = () => {
  return (dispatch, getState) => {
    let addonSelected = getState().cart.addonSelection;
    addonSelected.splice(0, 1);
    dispatch({
      type: actionTypes.SAVE_SELECTED_ADDON,
      payload: addonSelected,
    });
  };
};

export const setStoreCart = (id) => ({
  type: actionTypes.SET_STORE_CART,
  payload: id,
});

export const clearCart = () => ({
  type: actionTypes.CLEAR_CART,
});

export const addToCart = (item, store_id) => {
  return (dispatch, getState) => {
    dispatch(setCartLoading(item.itemid));
    const localCart = getState().cart.localCart;
    const storeCart = getState().cart.storeCart;
    if (storeCart && storeCart !== "" && storeCart !== store_id) {
      dispatch(clearCart());
      dispatch(saveOrder({}));
      dispatch(addToCart(item, store_id));
    } else {
      dispatch(setStoreCart(store_id));
      let add = localCart ? [...localCart] : [];
      if (item?.addonGroup?.length > 0 || item?.variation?.id) {
        const addItemIndex = add.findIndex(
          (val) =>
            val.itemid === item.itemid &&
            _.isEqual(val.addonGroup, item.addonGroup) &&
            _.isEqual(val.variation, item.variation)
        );

        const getAddonPrice = (i) => {
          let total = Number(i.price);
          if (i?.variation?.id) {
            total += Number(i?.variation?.price);
          }
          if (i?.addonGroup?.length > 0) {
            let temp = 0;
            i?.addonGroup?.forEach((obj) => {
              obj?.addon?.forEach((addon) => {
                temp += Number(addon.price);
              });
            });
            total += temp;
          }
          return total;
        };

        if (addItemIndex > -1) {
          console.log("addonPrice", getAddonPrice(item));
          add[addItemIndex]["qty"] += 1;
          add[addItemIndex]["total"] =
            getAddonPrice(item) * add[addItemIndex]["qty"];
        } else {
          let tempAdd = { ...item };
          tempAdd["qty"] = 1;
          tempAdd["note"] = "";
          tempAdd["total"] = getAddonPrice(item);
          add.push(tempAdd);
        }
      } else {
        const addIndex = add.findIndex((a) => a.itemid === item.itemid);
        if (addIndex > -1) {
          add[addIndex]["qty"] += 1;
          add[addIndex]["total"] =
            add[addIndex]["price"] * add[addIndex]["qty"];
        } else {
          const tempAdd = { ...item };
          tempAdd["qty"] = 1;
          tempAdd["note"] = "";
          tempAdd["total"] = Number(item.price);
          add.push(tempAdd);
        }
      }
      let data = {
        items: [],
        rest_id: store_id,
      };
      add.forEach((item) => {
        const temp = {};
        temp["id"] = item.itemid;
        temp["qty"] = item.qty;
        temp["note"] = "";
        if (item?.variation?.id) {
          temp["variation_name"] = item?.variation?.name;
          temp["variation_id"] = item?.variation?.variationid;
        }
        if (item?.addonGroup?.length > 0) {
          temp["addonGroup"] = item?.addonGroup;
        }
        data["items"].push(temp);
      });
      axios
        .post(`${API_URL}/urban-square/order`, data)
        .then(async (res) => {
          await dispatch({ type: actionTypes.SET_LOCAL_CART, payload: add });
          await dispatch(saveOrder(res.data));
          await dispatch(setCartLoading(""));
        })
        .catch((err) => {
          dispatch(setCartLoading(""));
        });
    }
  };
};

export const removeFromCart = (item) => {
  return (dispatch, getState) => {
    dispatch(setCartLoading(item.itemid));
    const localCart = getState().cart.localCart;
    const storeCart = getState().cart.storeCart;
    const remove = localCart ? [...localCart] : [];
    if (item?.addonGroup?.length > 0 || item?.variation?.id) {
      const removeItemIndex = remove.findIndex(
        (val) =>
          val.itemid === item.itemid &&
          _.isEqual(val.addonGroup, item.addonGroup) &&
          _.isEqual(val.variation, item.variation)
      );

      const getAddonPrice = (i) => {
        let total = Number(i.price);
        if (i?.variation?.id) {
          total += Number(i?.variation?.price);
        }
        if (i?.addonGroup?.length > 0) {
          let temp = 0;
          i?.addonGroup?.forEach((obj) => {
            obj?.addon?.forEach((addon) => {
              temp += Number(addon.price);
            });
          });
          total += temp;
        }
        return total;
      };

      if (removeItemIndex > -1) {
        if (remove[removeItemIndex]["qty"] === 1) {
          remove.splice(removeItemIndex, 1);
        } else {
          remove[removeItemIndex]["qty"] -= 1;
          remove[removeItemIndex]["total"] =
            getAddonPrice(item) * remove[removeItemIndex]["qty"];
        }
      }
    } else {
      const removeIndex = remove.findIndex((a) => a._id === item._id);
      if (removeIndex > -1) {
        if (remove[removeIndex]["qty"] === 1) {
          remove.splice(removeIndex, 1);
        } else {
          remove[removeIndex]["qty"] -= 1;
          remove[removeIndex]["total"] =
            remove[removeIndex]["price"] * remove[removeIndex]["qty"];
        }
      }
    }
    if (remove.length > 0) {
      let data = {
        items: [],
        rest_id: storeCart,
      };
      remove.forEach((item) => {
        const temp = {};
        temp["id"] = item.itemid;
        temp["qty"] = item.qty;
        temp["note"] = "";
        if (item?.variation?.id) {
          temp["variation_name"] = item?.variation?.name;
          temp["variation_id"] = item?.variation?.variationid;
        }
        if (item?.addonGroup?.length > 0) {
          temp["addonGroup"] = item?.addonGroup;
        }
        data["items"].push(temp);
      });
      axios
        .post(`${API_URL}/urban-square/order`, data)
        .then(async (res) => {
          await dispatch({ type: actionTypes.SET_LOCAL_CART, payload: remove });
          await dispatch(saveOrder(res.data));
          await dispatch(setCartLoading(""));
        })
        .catch((err) => {
          dispatch(setCartLoading(""));
        });
    } else {
      dispatch(setCartLoading(""));
      dispatch(clearCart());
      dispatch(saveOrder({}));
    }
  };
};
