import * as actionTypes from "../actionTypes";
import { http } from "../http";
import { API_URL, RZP_KEY } from "../../utils/constants";
import { toast } from "react-toastify";
import { push, replace } from "connected-react-router";
import { setMiscLoading } from "./miscActions";

export const saveOrder = (order) => ({
  type: actionTypes.SAVE_ORDER,
  payload: order,
});

export const setPaymentFetch = (status) => ({
  type: actionTypes.FETCH_PAYMENT_STATUS,
  payload: status,
});

export const updatePaymentMethod = (method) => ({
  type: actionTypes.UPDATE_PAYMENT_METHOD,
  payload: method,
});

export const createOrder = () => {
  return (dispatch, getState) => {
    dispatch(setMiscLoading(true));
    const cart = getState().cart.localCart;
    let data = {
      items: [],
      rest_id: cart[0].rest_id,
    };
    cart.forEach((item) => {
      let temp = {};
      temp["id"] = item.itemid;
      temp["qty"] = item.qty;
      temp["note"] = item.note;
      if (item?.variation?.id) {
        temp["variation_id"] = item?.variation?.variationid;
        temp["variation_name"] = item?.variation?.name;
      }
      if (item?.addonGroup?.length > 0) {
        temp["addonGroup"] = item.addonGroup;
      }
      data["items"].push(temp);
    });
    http
      .post(`${API_URL}/urban-square/order/pay`, data)
      .then(async (res) => {
        await dispatch(saveOrder(res.data));
        await dispatch(setMiscLoading(false));
        if (res.data?.data?.Payment?.details?.aggregator_order_id) {
          dispatch(
            razorPayCheckout(
              res.data?.data?.Payment?.details?.aggregator_order_id,
              res.data?.data?.Payment?.details?.amount * 100
            )
          );
        }
      })
      .catch(async (err) => {
        if (err && err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
        await dispatch(setMiscLoading(false));
      });
  };
};

// export const payOnline = (saleID) => {
//   return (dispatch) => {
//     dispatch(setMiscLoading(true));
//     http
//       .post(`${API_URL}/sale/${saleID}/pay`, {})
//       .then(async (res) => {
//         await dispatch(
//           saveOrder({
//             ...res.data.updatedSale,
//             onlinePayment: res.data.onlinePayment,
//           })
//         );
//         await dispatch({
//           type: actionTypes.LOADING_ORDER,
//           payload: false,
//         });
//       })
//       .catch((err) => {
//         if (err && err.response && err.response.data) {
//           toast.error(err.response.data.message);
//         }
//         dispatch(setMiscLoading(false));
//       });
//   };
// };

export const razorPayCheckout = (orderID, amount) => {
  return (dispatch, getState) => {
    // const razorpay = new window.Razorpay({
    //   key: RZP_KEY,
    //   callback_url: `${API_URL}/payments/razorpay/checkout?url=${UI_PATH}/profile`,
    //   redirect: false,
    // });
    dispatch(setMiscLoading(true));
    const user = getState().auth.userData;
    const options = {
      key: RZP_KEY,
      order_id: orderID,
      amount: amount,
      contact: user.phone,
      handler: function () {
        setTimeout(() => {
          dispatch(setMiscLoading(false));
          dispatch({ type: actionTypes.CLEAR_CART });
          dispatch({ type: actionTypes.RESET_ORDER });
          dispatch(replace("/profile"));
        }, 3000);
      },
      prefill: {
        contact: user.phone,
      },
    };
    const razorpay = new window.Razorpay(options);
    razorpay.open();
    // razorpay.createPayment(options);
    // razorpay.on('payment.success', async function (res) {
    //   dispatch({type: actionTypes.CLEAR_CART});
    //   dispatch({type: actionTypes.RESET_ORDER});
    //   dispatch(push('/profile'));
    // });
    razorpay.on("payment.error", function (err) {
      toast.error(err.error.description);
    });
  };
};

export const updateCurrentOrder = (orderID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    http
      .get(`${API_URL}/orders/${orderID}`)
      .then(async (res) => {
        if (res.data.curr_state === "payment-captured" || res.data.paid) {
          if (window.location.pathname !== "/profile") {
            await dispatch(push("/profile"));
            await dispatch({ type: actionTypes.CLEAR_CART });
            await dispatch({ type: actionTypes.RESET_ORDER });
          }
        } else {
          await dispatch(saveOrder(res.data));
        }
        await dispatch(setMiscLoading(false));
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
        dispatch(setMiscLoading(false));
      });
  };
};

export const getPreviousOrders = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_PREV_ORDERS_PENDING });
  try {
    const res = await http.get(`${API_URL}/urban-square/order/my-orders`);
    dispatch({ type: actionTypes.GET_PREV_ORDERS_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: actionTypes.GET_PREV_ORDERS_FAILED, payload: err });
  }
};

export const getPreviousOrdersPagination = (url) => {
  return (dispatch) => {
    http
      .get(url)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_PREV_ORDERS_PAGINATION,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const getOrderDetails = (orderID) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_ORDER_DETAILS_PENDING });
  const currentOrder = getState().order.order && getState().order.order._id;
  try {
    const res = await http.get(`${API_URL}/orders/${orderID}`);
    if (currentOrder === orderID && res.data.paid) {
      updateCurrentOrder(orderID);
    }
    dispatch({
      type: actionTypes.GET_ORDER_DETAILS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: actionTypes.GET_ORDER_DETAILS_FAILED, payload: err });
  }
};

export const resetOrderDetails = () => {
  return { type: actionTypes.GET_ORDER_DETAILS_SUCCESS, payload: null };
};

export const getTrackOrder = (orderID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    http
      .get(`${API_URL}/sale/${orderID}`)
      .then((res) => {
        dispatch({ type: actionTypes.SAVE_TRACK_ORDER, payload: res.data });
        dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};
