import * as actionTypes from "../actionTypes";

let initialState = {
  allTenants: [],
  selectedTenant: {},
  parentCategories: [],
  categories: [],
  items: [],
  variants: [],
  addonGroups: [],
};

export const restaurantReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ALL_TENANT_DETAILS:
      return {
        ...state,
        allTenants: action.payload,
      };
    case actionTypes.SET_SELECTED_TENANT_DETAILS:
      return {
        ...state,
        selectedTenant: action.payload,
      };
    case actionTypes.SET_TENANT_PARENT_CATEGORIES_DETAILS:
      return {
        ...state,
        parentCategories: action.payload,
      };
    case actionTypes.SET_TENANT_CATEGORIES_DETAILS:
      return {
        ...state,
        categories: action.payload,
      };
    case actionTypes.SET_TENANT_ITEMS_DETAILS:
      return {
        ...state,
        items: action.payload,
      };
    case actionTypes.SET_TENANT_VARIATION_DETAILS:
      return {
        ...state,
        variants: action.payload,
      };
    case actionTypes.SET_TENANT_ADDON_GROUP_DETAILS:
      return {
        ...state,
        addonGroups: action.payload,
      };
    case actionTypes.RESET_TENANT_MENU:
      return {
        ...state,
        selectedTenant: {},
        parentCategories: [],
        categories: [],
        items: [],
        variants: [],
        addonGroups: [],
      };
    default:
      return state;
  }
};
