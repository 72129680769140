import * as actionTypes from "../actionTypes";

let initialState = {
  loading: false,
  veg: false,
};

export const miscReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MISC_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.SET_VEG:
      return {
        ...state,
        veg: action.payload,
      };
    default:
      return state;
  }
};
