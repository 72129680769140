import React from 'react';

function Menu() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.5"
        x="0.75"
        y="0.75"
        width="28.5"
        height="28.5"
        rx="8.25"
        fill="white"
        stroke="#A2A9B4"
        strokeWidth="0.5"
      />
      <path
        d="M8 9.99997C8 8.89542 8.89542 8 9.99997 8H11.9999C13.1045 8 13.9999 8.89542 13.9999 9.99997V11.9999C13.9999 13.1045 13.1045 13.9999 11.9999 13.9999H9.99997C8.89542 13.9999 8 13.1045 8 11.9999V9.99997Z"
        fill="#A2A9B4"
      />
      <path
        d="M8 17.9999C8 16.8953 8.89542 15.9999 9.99997 15.9999H11.9999C13.1045 15.9999 13.9999 16.8953 13.9999 17.9999V19.9998C13.9999 21.1044 13.1045 21.9998 11.9999 21.9998H9.99997C8.89542 21.9998 8 21.1044 8 19.9998V17.9999Z"
        fill="#A2A9B4"
      />
      <path
        d="M16.0002 9.99997C16.0002 8.89542 16.8956 8 18.0002 8H20.0002C21.1047 8 22.0001 8.89542 22.0001 9.99997V11.9999C22.0001 13.1045 21.1047 13.9999 20.0002 13.9999H18.0002C16.8956 13.9999 16.0002 13.1045 16.0002 11.9999V9.99997Z"
        fill="#A2A9B4"
      />
      <path
        d="M16.0002 17.9999C16.0002 16.8953 16.8956 15.9999 18.0002 15.9999H20.0002C21.1047 15.9999 22.0001 16.8953 22.0001 17.9999V19.9998C22.0001 21.1044 21.1047 21.9998 20.0002 21.9998H18.0002C16.8956 21.9998 16.0002 21.1044 16.0002 19.9998V17.9999Z"
        fill="#A2A9B4"
      />
    </svg>
  );
}

export default Menu;
