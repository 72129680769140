import React from "react";
import { Route, Redirect } from "react-router";
import { useSelector } from "react-redux";

function PrivateRouteHoc({ component: Component, ...rest }) {
  const userData = useSelector((state) => state.auth.userData);
  const token = userData.token ? userData.token : "";
  const ref = rest.location.pathname.slice(1);
  return (
    <Route
      {...rest}
      render={(prop) =>
        token !== "" ? (
          <Component {...prop} />
        ) : (
          <Redirect to={`/login?ref=${ref}`} />
        )
      }
    />
  );
}

export default PrivateRouteHoc;
