import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    position: "relative",
    marginBottom: theme.spacing(2),
    boxShadow: `0px 0px 2px ${theme.palette.text.secondary}`,
  },
  headRoot: {
    padding: theme.spacing(8),
    display: "flex",
    zIndex: 100,
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  heading: {
    color: theme.palette.text.secondary,
    fontSize: 10,
    fontWeight: 500,
    letterSpacing: 0.5,
    marginLeft: theme.spacing(5),
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    marginLeft: theme.spacing(5),
  },
  subTitle: {
    fontSize: 13,
    marginLeft: theme.spacing(5),
  },
  aboutUS: {
    color: "#2F80ED",
    fontSize: 11,
    marginLeft: 8,
    textDecoration: "underline",
  },
  rightFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  accountIcon: {
    padding: "0 !important",
  },
  selectVeg: {
    marginRight: theme.spacing(5),
  },
  veg: {
    fontWeight: 500,
    fontSize: 10,
    textAlign: "center",
  },
  about: {
    minHeight: 236,
    backgroundSize: "cover",
    marginBottom: 12,
    color: "#fff",
    position: "relative",
  },
  content: {
    position: "absolute",
    bottom: 16,
  },
  aboutTitle: {
    fontSize: 18,
    fontWeight: 600,
    padding: 16,
  },
  aboutSubtitle: {
    fontSize: 13,
    padding: "16px 16px 0",
    height: 50,
    overflow: "hidden",
  },
  aboutReadmore: {
    padding: "2px 16px 16px",
    color: "#F53B57",
    fontSize: 13,
  },
  dialogTitle: {
    position: "absolute",
    bottom: 24,
    left: 16,
    fontSize: 18,
    fontWeight: 600,
    color: "#fff",
  },
  dialogContent: {
    padding: 16,
  },
  bottomWTF: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid rgba(33, 42, 57, 0.25)",
    paddingTop: 16,
  },
  fadedtext: {
    fontWeight: 600,
    fontSize: 18,
    color: theme.homepage.color,
  },
}));
