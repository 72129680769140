import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { miscReducers } from "./miscReducers";
import { restaurantReducers } from "./restaurantReducers";
import { cartReducers } from "./cartReducers";
import authReducers from "./authReducers";
import orderReducers from "./orderReducers";

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    misc: miscReducers,
    restaurant: restaurantReducers,
    cart: cartReducers,
    auth: authReducers,
    order: orderReducers,
  });

export default reducers;
