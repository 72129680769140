export const SAVE_ORDER = "SAVE_ORDER";
export const FETCH_PAYMENT_STATUS = "FETCH_PAYMENT_STATUS";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const RESET_ORDER = "RESET_ORDER";

export const SAVE_TRACK_ORDER = "SAVE_TRACK_ORDER";

export const GET_PREV_ORDERS_PENDING = "GET_PREV_ORDERS_PENDING";
export const SET_PREV_ORDERS_PAGINATION = "SET_PREV_ORDERS_PAGINATION";
export const GET_PREV_ORDERS_SUCCESS = "GET_PREV_ORDERS_SUCCESS";
export const GET_PREV_ORDERS_FAILED = "GET_PREV_ORDERS_FAILED";

export const GET_ORDER_DETAILS_PENDING = "GET_ORDER_DETAILS_PENDING";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAILED = "GET_ORDER_DETAILS_FAILED";
