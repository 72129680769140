import * as actionTypes from "../actionTypes";

const initialState = {
  previousOrders: {
    result: [],
    __metadata: {},
  },
  previousOrdersError: {},
  previousOrdersPending: false,
  order: {},
  orderDetails: null,
  orderDetailsPending: false,
  orderDetailsError: null,
  trackOrder: {},
  paymentMode: "",
};

export default function orderReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case actionTypes.GET_PREV_ORDERS_PENDING:
      return {
        ...state,
        previousOrdersPending: true,
      };
    case actionTypes.GET_PREV_ORDERS_SUCCESS:
      return {
        ...state,
        previousOrders: action.payload,
        previousOrdersPending: false,
      };
    case actionTypes.SET_PREV_ORDERS_PAGINATION: {
      const temp = { ...state.previousOrders };
      if (temp.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            temp.result.push(res);
          });
      }
      temp["__metadata"] = action.payload.__metadata;
      return {
        ...state,
        previousOrders: temp,
      };
    }
    case actionTypes.GET_PREV_ORDERS_FAILED:
      return {
        ...state,
        previousOrdersError: action.payload,
        previousOrdersPending: true,
      };
    case actionTypes.GET_ORDER_DETAILS_PENDING:
      return {
        ...state,
        orderDetailsPending: true,
      };
    case actionTypes.GET_ORDER_DETAILS_SUCCESS:
      const tempOrders = [...state.orders];
      let tempTrackOrder = { ...state.trackOrder };
      if (tempTrackOrder && tempTrackOrder._id === action.payload._id) {
        tempTrackOrder = { ...action.payload };
      }
      if (tempOrders.length > 0) {
        const tempIndex = tempOrders.findIndex(
          (a) => a._id === action.payload._id
        );
        if (tempIndex > -1) {
          tempOrders[tempIndex] = {
            ...action.payload,
            store_id: tempOrders[tempIndex].store_id,
          };
        } else {
          tempOrders.push(action.payload);
        }
      }
      return {
        ...state,
        orderDetailsPending: false,
        orders: tempOrders,
        trackOrder: tempTrackOrder,
        orderDetails: action.payload,
      };
    case actionTypes.GET_ORDER_DETAILS_FAILED:
      return {
        ...state,
        orderDetailsPending: false,
        orderDetailsError: action.payload,
      };
    case actionTypes.SAVE_TRACK_ORDER:
      return {
        ...state,
        trackOrder: action.payload,
      };
    case actionTypes.UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMode: action.payload,
      };
    case actionTypes.RESET_ORDER:
      return {
        previousOrders: {
          result: [],
          __metadata: {},
        },
        previousOrdersError: {},
        previousOrdersPending: false,
        order: {},
        orderDetails: null,
        orderDetailsPending: false,
        orderDetailsError: null,
        trackOrder: {},
        paymentMode: "",
      };
    default:
      return state;
  }
}
