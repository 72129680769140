export const SET_ALL_TENANT_DETAILS = "SET_ALL_TENANT_DETAILS";

export const SET_SELECTED_TENANT_DETAILS = "SET_SELECTED_TENANT_DETAILS";
export const SET_TENANT_PARENT_CATEGORIES_DETAILS =
  "SET_TENANT_PARENT_CATEGORIES_DETAILS";
export const SET_TENANT_CATEGORIES_DETAILS = "SET_TENANT_CATEGORIES_DETAILS";
export const SET_TENANT_ITEMS_DETAILS = "SET_TENANT_ITEMS_DETAILS";
export const SET_TENANT_ADDON_GROUP_DETAILS = "SET_TENANT_ADDON_GROUP_DETAILS";
export const SET_TENANT_VARIATION_DETAILS = "SET_TENANT_VARIATION_DETAILS";

export const RESET_TENANT_MENU = "RESET_TENANT_MENU";
