import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: { main: "#212A39" },
    secondary: { main: "rgba(0, 0, 0, 0.36)" },
    background: {
      default: "#FFFFFF",
    },
    accent: "#F6A61E",
    text: {
      primary: "rgba(33, 42, 57, 0.87)",
      secondary: "rgba(33, 42, 57, 0.5)",
      secondary1: "rgba(0, 0, 0, 0.05)",
      invert: "rgba(255, 255, 255, 0.87)",
    },
    border: {
      default: "1px solid rgba(0, 0, 0, 0.07)",
      secondary: "#E4E6E9",
    },
    shadow: "0px 1px 2px rgba(0, 0, 0, 0.09)",
    accordian: {
      border: "rgba(0, 0, 0, 0.12)",
    },
    mustTry: {
      background: "#FFFBEF",
    },
    tip: {
      background: "#F1F1F1",
    },
  },
  spacing: 2,
  homepage: {
    background: "#F1F5FF",
    color: "#C1C1C1",
  },
  tip: {
    boxShadow: "rgba(0,0,0,0.1)",
  },
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

export const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: { main: "rgba(255,255,255, 0.87)" },
    secondary: { main: "rgba(255,255,255, 0.36)" },
    accent: "#F6A61E",
    text: {
      primary: "rgba(255, 255, 255, 0.87)",
      secondary: "rgba(255, 255, 255, 0.5)",
      secondary1: "rgba(255, 255, 255, 0.05)",
      invert: "rgba(33, 42, 57, 0.87)",
    },
    border: {
      default: "1px solid rgba(255, 255, 255, 0.17)",
      secondary: "#E4E6E9",
    },
    shadow: "0px 1px 2px rgba(0, 0, 0, 0.79)",
    accordian: {
      border: "rgba(255, 255, 255, 0.12)",
    },
    mustTry: {
      background: "#A3A29F",
    },
    tip: {
      background: "#A3A29F",
    },
  },
  spacing: 2,
  homepage: {
    background: "#212121",
    color: "#C1C1C1",
  },
  tip: {
    boxShadow: "rgba(255,255,255,0.1)",
  },
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
