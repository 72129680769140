import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { Route, Switch } from "react-router";
import suspenseHoc from "./hoc/suspenseHoc";
import headerHoc from "./hoc/headerHoc";
import { lightTheme, darkTheme } from "./utils/themes/theme";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingModal from "./components/LoadingModal";
import PrivateRouteHoc from "./hoc/PrivateRouteHoc";

const LandingPage = React.lazy(() => import("./pages/LandingPage"));
const MenuPage = React.lazy(() => import("./pages/MenuPage"));
const CartPage = React.lazy(() => import("./pages/CartPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const ProfilePage = React.lazy(() => import("./pages/ProfilePage"));

function App() {
  React.useEffect(() => {
    window.onload = function () {
      const appVersion = window.localStorage.getItem("appVersion");
      if (!appVersion) {
        window.localStorage.setItem("appVersion", "22.01.13m");
      } else if (appVersion !== "22.01.13m") {
        toast.info("Please wait, updating new version...");
        setTimeout(() => {
          window.localStorage.clear();
          window.localStorage.setItem("appVersion", "22.01.13m");
          window.location.reload();
        }, 3000);
      }
    };
  }, []);

  const theme = window.localStorage.theme ? window.localStorage.theme : "light";

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <Switch>
        <Route exact path="/" component={suspenseHoc(headerHoc(LandingPage))} />
        <Route
          exact
          path="/menu/:restID"
          component={suspenseHoc(headerHoc(MenuPage))}
        />
        <Route exact path="/cart" component={suspenseHoc(CartPage)} />
        <Route exact path="/login" component={suspenseHoc(LoginPage)} />
        <PrivateRouteHoc path="/profile" component={suspenseHoc(ProfilePage)} />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable
        pauseOnHover={false}
      />
      <LoadingModal />
    </ThemeProvider>
  );
}

export default App;
