import * as actionTypes from "../actionTypes";

export const setMiscLoading = (data) => ({
  type: actionTypes.SET_MISC_LOADING,
  payload: data,
});

export const setVeg = (data) => ({
  type: actionTypes.SET_VEG,
  payload: data,
});
