import React from "react";
import Dialog from "@mui/material/Dialog";
import Lottie from "react-lottie";
import * as animationDataLoading from "../../assets/loading/color-dots.json";
import { useSelector } from "react-redux";

function LoadingModal() {
  const loading = useSelector((state) => state.misc.loading);
  const cartLoading = useSelector((state) => state.cart.loading);
  const defaultLoading = {
    loop: true,
    autoplay: true,
    animationData: animationDataLoading.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Dialog
      style={{ zIndex: 1500 }}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      maxWidth={"xs"}
      open={loading || cartLoading}
    >
      <Lottie
        options={defaultLoading}
        height={300}
        width={300}
        isClickToPauseDisabled={true}
      />
    </Dialog>
  );
}

export default LoadingModal;
